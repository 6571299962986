import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastyService } from 'ng2-toasty';
import role from '../role.json';

import { BackendApiService } from '../../../services/backend-api/backend-api.service';
import { UtilService } from '../../../services/util/util.service';
import { RequestService } from '../../../services/request/request.service';
import { TranslateService } from '@ngx-translate/core';

import { AlertConfirmationComponent } from '../../alert-confirmation/alert-confirmation.component';
import { AlertMessageComponent } from '../../query/alert-message/alert-message.component';

import { tableValue } from '../../../services/tableClass'
import { MatomoTracker } from 'ngx-matomo';
import { timer } from 'rxjs';

@Component({
  selector: 'app-choice-table',
  templateUrl: './choice-table.component.html',
  styleUrls: ['./choice-table.component.scss']
})

export class ChoiceTableComponent implements OnInit {
  role = role;
  alertMsg = [{ 'EUROPE': [] },
  { 'MERCOSUR': [] }];
  tableValues = {
    "ipn": undefined, "region": undefined, "responseData": undefined,
    "tableData": undefined, "selectedRegion": undefined, "selectedTable": undefined, "selectedTableDetails": undefined,
    "dateDiff": undefined, "requestDatas": undefined, "roleBasedTableList": undefined
  };
  profile = this.utilService.getUserProfile();
  isAdmin = false;
  roleBasedGroupList;

  isLink: boolean;

  sorter = this.utilService.MySort(' ?*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyz');
  blnQuickLink: boolean;

  constructor(readonly router: Router, readonly activatedRoute: ActivatedRoute, public dialog: MatDialog,
    readonly apiService: BackendApiService, readonly utilService: UtilService,
    private translateService: TranslateService,
    readonly requestService: RequestService, readonly toastyService: ToastyService, private matomoTracker: MatomoTracker) {
    this.utilService.$showIcon$.next(false);
  }

  ngOnInit() {

    //IDP
    /*const role = this.utilService.getUserProfile()['role-acc-pil-irn6804'];
    const role = this.utilService.getUserProfile()['roles'].filter(item => item.indexOf('ACC_P') > -1);
    let regionRole = role.replace('[', '').replace(']', '');
    regionRole = regionRole.split(',');*/

    //OKTA
    let regionRole = this.utilService.getUserProfile()['roles'].filter(item => item.indexOf('ACC_P') > -1);
    if (regionRole.length > 1) {
      this.tableValues.region = ['Europe', 'Mercosur'];
      this.tableValues.selectedRegion = "Europe";
    } else {
      //IDP
      // if (regionRole[0] === 'acc_pil_europe') {

      //OKTA
      // if (regionRole[0] === 'ACC_PIL_EUROPE') {
      //   this.tableValues.region = ['Europe'];
      //   this.tableValues.selectedRegion = "Europe";
      // } else {
      //   this.tableValues.region = ['Mercosur'];
      //   this.tableValues.selectedRegion = "Mercosur";
      // }
      
      let region: string;
      regionRole[0] === 'ACC_PIL_EUROPE' ? region = 'Europe' : region = 'Mercosur';
      this.tableValues.region = [region];
      this.tableValues.selectedRegion = region;
    }

    //IDP
    //this.tableValues.ipn = this.profile['uid']; // sessionStorage.getItem("ipn");

    //OKTA
    this.tableValues.ipn = this.profile['login']; // sessionStorage.getItem("ipn");

    if (sessionStorage.getItem("request1") === null) {
      [1,2,3].forEach(cnt => sessionStorage.setItem("request"+cnt, undefined));
    }
    this.tableValues.requestDatas = this.requestService.getAllRequestData();
    this.getChoiceTableDetails(this.tableValues.selectedRegion);


    this.utilService.$showQuickLink$.subscribe(urlLink => {
      this.blnQuickLink = urlLink;
    });

    this.matomoTracker.trackPageView(`New request`);

  }

  getRegionVal() {
    let region = this.requestService.getRegionMsg()
  }

  allowAllHistoricalData() {
    //IDP
    /*if (this.tableValues.selectedRegion === "Europe") {
      if (this.profile['role-pil-irn6804'].indexOf('pil_admin') > -1) {
        this.isAdmin = true;
      }
    } else {
      if (this.profile['role-pim-irn9207'].indexOf('pim_admin') > -1) {
        this.isAdmin = true;
      }
    }*/

    //OKTA
    if (this.tableValues.selectedRegion === "Europe") {
      if (this.profile['roles'].indexOf('PIL_ADMIN') > -1) {
        this.isAdmin = true;
      }
    } else {
      if (this.profile['roles'].indexOf('PIM_ADMIN') > -1) {
        this.isAdmin = true;
      }
    }
  }

  getUserRole4TabeList() {
    let rtnValue;

    //IDP
    /*if (this.tableValues.selectedRegion === "Europe") {
      rtnValue = this.profile['role-gr-pil-irn6804'];
    } else {
      rtnValue = this.profile['role-gr-pim-irn9207'];
    }*/

    //OKTA
    if (this.tableValues.selectedRegion === "Europe") {
      // rtnValue = this.profile['roles'].filter(item => item.indexOf('GR_PIL_TOUT') > -1)[0];
      rtnValue = this.profile['roles'].find(item => item.startsWith('GR_PIL'));
    } else {
      rtnValue = this.profile['roles'].find(item => item.startsWith('GR_PIM'));
    }
    return rtnValue;
  }

  getFavoriteRegion() {
    if (this.tableValues.selectedRegion === "Europe") {
      return "PIL";
    } else {
      return "PIM";
    }
  }

  getChoiceTableDetails(item) {
    this.tableValues.tableData = undefined;
    this.tableValues.selectedRegion = item;
    this.allowAllHistoricalData();
    this.getRoleBasedTableNamesList();

    const queryParam = { "input": `MAJ@${this.tableValues.ipn}@@@0@11@`, "region": this.getFavoriteRegion() };
    this.apiService.getChoiceTableDetails(queryParam).subscribe(res => {
      this.tableValues.responseData = res;
      
      if (this.tableValues.responseData.lists && this.getUserRole4TabeList()) {
        this.tableValues.selectedTable = String(this.tableValues.responseData.lists[0].table.toLocaleUpperCase());
        let date = this.tableValues.responseData.lists[0].fileUpdtdDate.split(' ');
        this.tableValues.selectedTableDetails = this.utilService.getTranslateName(`NEW_QUERY.DATE_UPDATED`) + String(date[1]) + this.utilService.getTranslateName(`NEW_QUERY.AT`) + String(date[3]);
        const strUpdDate = String(this.tableValues.responseData.lists[0].fileUpdtdDate).substring(3, 13);
        const strRecDate = String(this.tableValues.responseData.lists[0].fileRecvdDate).substring(3, 13);
        const updDate = new Date(`${strUpdDate.substring(3, 5)}/${strUpdDate.substring(0, 2)}/${strUpdDate.substring(6, 10)}`);
        const recDate = new Date(`${strRecDate.substring(3, 5)}/${strRecDate.substring(0, 2)}/${strRecDate.substring(6, 10)}`);

        const strServerDate = String(this.tableValues.responseData.lists[0].serverDate);
        const serverDate = new Date(`${strServerDate.substring(3, 5)}/${strServerDate.substring(0, 2)}/${strServerDate.substring(6, 10)}`);

        const diff = Math.abs(updDate.getTime() - serverDate.getTime());
        const diffDays = Math.floor(diff / (1000 * 3600 * 24));
        // console.log("server Date", diffDays, serverDate, updDate);
        this.tableValues.dateDiff = diffDays;
        if (this.tableValues.responseData.noOfLists > 0) {
          this.getTableNamesList();

          let region = this.requestService.getRegionMsg();
          let queryParam = { "input": "MESS@system@Message@@0@1@", "region": this.tableValues.selectedRegion.toLowerCase() === 'europe' ? 'PIL' : 'PIM' };
          this.apiService.getMessageObjectList(queryParam).subscribe(res => {
            if (res.lists !== undefined && res.lists.length > 0) {
              if (res.lists[0] === 'tmp') {
                if (region['europe'] === 'false' || region['mercosur'] === 'false') {
                  this.displayMessage(region, res.lists[0]);
                }
              } else {
                if (this.tableValues.selectedRegion.toLowerCase() === 'europe') {
                  this.requestService.addRegionMsg('false', region['mercosur']);
                  this.getEuropeMsg(res.lists[0]);
                } else {
                  this.requestService.addRegionMsg(region['europe'], 'false');
                  this.getMercosurMsg(res.lists[0]);
                }
              }
            }
          });
        }
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
        console.log("error", error.message);
      });
  }

  getRoleBasedTableNamesList() {
    let role;
    this.tableValues.roleBasedTableList = [];
    if (this.getUserRole4TabeList()) {
      if (this.getUserRole4TabeList().split("_")[2].toLowerCase() !== 'tout') {
        role = this.getUserRole4TabeList().toLowerCase().replace(`gr_${this.getFavoriteRegion().toLowerCase()}_`, '');
        const queryParam1 = { "input": `PROFIL@system@profil@${role.toLocaleUpperCase()}@`, "region": this.getFavoriteRegion() };
        this.apiService.getObjectGet(queryParam1).subscribe(res => {
          if (res && res.data) {
            this.roleBasedGroupList = res.data;
            for (const item of res.data.split('§')) {
              if (item.split("@")[1] === "0") {
                this.tableValues.roleBasedTableList.push(item.split("@")[0]);
              }
            }
          }
        },
          error => {
            this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
            console.log("error", error.message);
          });
      } else {
        this.roleBasedGroupList = '';
      }
    }
  }

  getTableNamesList() {
    const queryParam1 = { "input": `TABGEN@${this.tableValues.ipn}@@@0@${this.tableValues.responseData.noOfLists}@`, "region": this.getFavoriteRegion() };
    this.apiService.getChoiceTableDetails(queryParam1).subscribe(res => {
      let tabData = [];
      tabData = res.lists;
      if (tabData) {
        this.tableValues.tableData = [];
        let i = 0;
        for (const item of tabData) {
          const td = this.tableValues.responseData.lists.filter(a => String(a.table).toLocaleUpperCase() === String(item).toLocaleUpperCase());
          const roleTable = this.tableValues.roleBasedTableList.filter(a => String(a).toLocaleUpperCase() === String(item).toLocaleUpperCase());
          if (td.length > 0 && roleTable.length === 0) {
            this.tableValues.tableData.push({ "name": String(item).toLocaleUpperCase(), "fileRecvdDate": td[0].fileRecvdDate, "fileUpdtdDate": td[0].fileUpdtdDate })
          }
          i = i + 1;
        }
        this.tableValues.tableData = this.tableValues.tableData.sort();
        if (this.tableValues.tableData.length > 0) {
          if (this.tableValues.tableData.indexOf('BCV') < 0) {
            this.tableSelection(this.tableValues.tableData[0]);
          }
        }
      }
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.TABLE_ERROR`));
        console.log("error", error.message);
      });
  }

  tableSelection(item) {
    this.tableValues.dateDiff = undefined;
    setTimeout(() => { this.tableValues.selectedTable = item.name; }, 1);

    let region = this.requestService.getRegionMsg();
    this.utilService.$favoriteConnecton$.next(this.tableValues.selectedRegion);


    // this.tableValues.selectedTableDetails = this.utilService.getTranslateName(`NEW_QUERY.DATE_UPDATED`) + ' ' + String(item.fileUpdtdDate)
    // this.tableValues.selectedTableDetails = this.utilService.getTranslateName(`NEW_QUERY.DATE_UPDATED`) + ' ' + String(item.fileUpdtdDate);
    let date = item.fileUpdtdDate.split(' ');
    this.tableValues.selectedTableDetails = this.utilService.getTranslateName(`NEW_QUERY.DATE_UPDATED`) + String(date[1]) + this.utilService.getTranslateName(`NEW_QUERY.AT`) + String(date[3]);
    const strUpdDate = String(item.fileUpdtdDate).substring(3, 13);
    const strRecDate = String(item.fileRecvdDate).substring(3, 13);
    const updDate = new Date(`${strUpdDate.substring(3, 5)}/${strUpdDate.substring(0, 2)}/${strUpdDate.substring(6, 10)}`);
    const recDate = new Date(`${strRecDate.substring(3, 5)}/${strRecDate.substring(0, 2)}/${strRecDate.substring(6, 10)}`);

    const strServerDate = String(this.tableValues.responseData.lists[0].serverDate);
    const serverDate = new Date(`${strServerDate.substring(3, 5)}/${strServerDate.substring(0, 2)}/${strServerDate.substring(6, 10)}`);

    const diff = Math.abs(updDate.getTime() - serverDate.getTime());
    // const diff = Math.abs(updDate.getTime() - new Date().getTime());
    const diffDays = Math.floor(diff / (1000 * 3600 * 24));
    // console.log("server Date", diffDays, serverDate, updDate);
    this.tableValues.dateDiff = diffDays;
  }

  submit() {
    if (this.tableValues.selectedTable !== 'CARNETS' && this.tableValues.selectedTable !== 'LEX' && this.tableValues.dateDiff > 0) {
      this.openDialog();
    } else {
      if (this.tableValues.requestDatas) {
        this.matomoTracker.trackEvent("New request", `Create ${this.tableValues.selectedRegion} - ${this.tableValues.selectedTable} new query`, this.profile['login']);
        if (this.tableValues.requestDatas.length < 3) {
          this.requestService.addRequestData(this.tableValues.selectedRegion, this.tableValues.selectedTable, this.tableValues.requestDatas);
          // this.removeQueryWindowNameInList();
          this.setOpenedWindow('/home/query');
          this.router.navigateByUrl('/home/query');
          this.utilService.$showQuickLink$.next(true);
        } else {
          // this.removeQueryWindowNameInList();
          this.setOpenedWindow('/home/query');
          this.router.navigateByUrl('/home/query');
          this.toastyService.info(this.utilService.getTranslateName(`ERROR.MAX_REQ`));
          this.utilService.$showQuickLink$.next(true);
          // alert("User can create request maximum 3.")
        }
      } else {
        this.matomoTracker.trackEvent("New request", `Create ${this.tableValues.selectedRegion} - ${this.tableValues.selectedTable} new query`, this.profile['login']);
        this.requestService.addRequestData(this.tableValues.selectedRegion, this.tableValues.selectedTable, this.tableValues.requestDatas);
        // this.removeQueryWindowNameInList();
        this.setOpenedWindow('/home/query');
        this.router.navigateByUrl('/home/query');
        this.utilService.$showQuickLink$.next(true);
      }
      let reqActive;
      if ((this.tableValues.requestDatas.length < 4)) {
        if (this.tableValues.requestDatas[this.tableValues.requestDatas.length - 1] != null) {
          reqActive = this.tableValues.requestDatas.length;
        } else {
          reqActive = 0;
        }
      }
      if (this.roleBasedGroupList !== '') {
        let tablesList = this.roleBasedGroupList.split('§');
        tablesList.forEach(item => {
          if (item.split('@')[0] === this.tableValues.selectedTable) {
            if (item.split('@')[1] !== '0') {
              this.requestService.roleBasedQuery(item, reqActive);
            } else {
              this.requestService.roleBasedQuery('', reqActive);
            }
          }
        })
      }
    }
  }

  setOpenedWindow(windowPathName) {
    this.utilService.addOpenedWindowName(windowPathName);
  }

  reset() {
    const queryParam = { "input": `${this.tableValues.selectedTable}@`, "region": this.getFavoriteRegion() };
    this.apiService.memoryReleaseObject(queryParam).subscribe(resp => {
      this.toastyService.success("Memory released successfully");
    },
      error => {
        this.toastyService.error(this.utilService.getTranslateName(`ERROR.DELETE`));
        console.log("error", error.message);
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AlertConfirmationComponent, {
      disableClose: true,
      width: '550px',
      data: { 'display': this.utilService.getTranslateName(`ERROR.OLD_DATA`), 'isText': false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (this.tableValues.requestDatas) {
          if (this.tableValues.requestDatas.length < 3) {
            this.requestService.addRequestData(this.tableValues.selectedRegion, this.tableValues.selectedTable, this.tableValues.requestDatas);
            // this.removeQueryWindowNameInList();
            this.setOpenedWindow('/home/query');
            this.router.navigateByUrl('/home/query');
          } else {
            // this.removeQueryWindowNameInList();
            this.setOpenedWindow('/home/query');
            this.router.navigateByUrl('/home/query');
            this.toastyService.info(this.utilService.getTranslateName(`ERROR.MAX_REQ`));
            // alert("User can create request maximum 3.")
          }
        } else {
          this.requestService.addRequestData(this.tableValues.selectedRegion, this.tableValues.selectedTable, this.tableValues.requestDatas);
          // this.removeQueryWindowNameInList();
          this.setOpenedWindow('/home/query');
          this.router.navigateByUrl('/home/query');
        }
        let reqActive;
        if ((this.tableValues.requestDatas.length < 4)) {
          if (this.tableValues.requestDatas[this.tableValues.requestDatas.length - 1] != null) {
            reqActive = this.tableValues.requestDatas.length;
          } else {
            reqActive = 0;
          }
        }
        let tablesList = this.roleBasedGroupList.split('§');
        tablesList.forEach(item => {
          if (item.split('@')[0] === this.tableValues.selectedTable) {
            if (item.split('@')[1] !== '0') {
              this.requestService.roleBasedQuery(item, reqActive);
            } else {
              this.requestService.roleBasedQuery('', reqActive);
            }
          }
        })
      }
    });
  }

  displayMessage(region, msgType) {
    if (this.tableValues.selectedRegion.toLowerCase() === 'europe') {
      if (region['mercosur'] === 'false') {
        this.requestService.addRegionMsg('true', 'false');
      } else {
        this.requestService.addRegionMsg('true', 'true');
      }
      if (region['europe'] === 'false') {
        this.getEuropeMsg(msgType)
      }
    }
    if (this.tableValues.selectedRegion.toLowerCase() === 'mercosur') {
      if (region['europe'] === 'false') {
        this.requestService.addRegionMsg('false', 'true');
      } else {
        this.requestService.addRegionMsg('true', 'true');
      }
      if (region['mercosur'] === 'false') {
        this.getMercosurMsg(msgType)
      }
    }
  }

  linkIndex = [];
  getEuropeMsg(msgType) {
    this.linkIndex = [];
    let queryParam1 = { "input": `MESS@system@Message@${msgType}@`, "region": 'PIL' };
    this.apiService.getMessageObject(queryParam1).subscribe(res => {

      if (res.data !== '') {
        this.alertMsg[0].EUROPE[0] = res.data;
        if (this.alertMsg[0].EUROPE[0].includes('<BR>')) {
          const splitVal = this.alertMsg[0].EUROPE[0].split('<BR>');
          for (let index = 0; index < splitVal.length; index++) {
            const element = splitVal[index];
            if (element !== "") {
              this.isLink = element.indexOf("http") === -1 ? false : true;
              if (element.indexOf("http") !== -1) {
                this.linkIndex.push(index);
              }
            }
            // console.log("element", element, element.indexOf("http"));
            if (index === splitVal.length - 1) {
              if (element !== "") {
                this.alertMsg[0].EUROPE[index] = element;
              }
            } else {
              this.alertMsg[0].EUROPE[index] = element;
            }
          }
          // this.alertMsg[0].EUROPE = this.alertMsg[0].EUROPE[0].split('<BR>');
          // this.alertMsg[0].EUROPE[1] = this.alertMsg[0].EUROPE[1];
        }
        this.displayPopup();
      }
    });
  }

  getMercosurMsg(msgType) {
    this.linkIndex = [];
    let queryParam1 = { "input": `MESS@system@Message@${msgType}@`, "region": 'PIM' };
    this.apiService.getMessageObject(queryParam1).subscribe(res => {
      if (res.data !== '') {
        this.alertMsg[1].MERCOSUR[0] = res.data;
        // if (this.alertMsg[1].MERCOSUR[0].includes('<BR>')) {
        //   this.alertMsg[1].MERCOSUR = this.alertMsg[1].MERCOSUR[0].split('<BR>');
        //   this.alertMsg[1].MERCOSUR[1] = this.alertMsg[1].MERCOSUR[1];
        // }
        if (this.alertMsg[1].MERCOSUR[0].includes('<BR>')) {
          const splitVal = this.alertMsg[1].MERCOSUR[0].split('<BR>');
          for (let index = 0; index < splitVal.length; index++) {
            const element = splitVal[index];
            if (element !== "") {
              this.isLink = element.indexOf("http") === -1 ? false : true;
              if (element.indexOf("http") !== -1) {
                this.linkIndex.push(index);
              }
            }
            if (index === splitVal.length - 1) {
              if (element !== "") {
                this.alertMsg[1].MERCOSUR[index] = element;
              }
            } else {
              this.alertMsg[1].MERCOSUR[index] = element;
            }
            // this.alertMsg[1].MERCOSUR[index] = element;
          }
        }

        this.displayPopup();
      }
    });
  }

  displayPopup() {
    if (this.alertMsg.length > 0) {
      // console.log("this.alertMsg", this.linkIndex, this.alertMsg)
      const dialogRef = this.dialog.open(AlertMessageComponent, {
        disableClose: true,
        width: '800px',
        data: { 'display': this.alertMsg, "linkIndex": this.linkIndex, region: this.tableValues.selectedRegion.toLowerCase(), type: 'region', isLink: this.isLink }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
        }
      })
    }
  }

  clickClose() {
    const pathName = window.location.pathname;
    this.utilService.removeOpenedWindowName(pathName);
    this.utilService.$showIcon$.next(true);
    const openedWindowNameList = this.utilService.getOpenedWindowNameList()
    if (openedWindowNameList.length > 0) {
      this.router.navigate([openedWindowNameList[openedWindowNameList.length - 1]]);
    } else {
      this.router.navigate(['home']);
    }
  }

  reDirectURL(url) {
    this.router.navigateByUrl(url);
  }
}
